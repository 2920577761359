import React, {useState, useEffect} from "react";
import {Carousel} from "antd"; // Ensure you import Carousel from the correct library
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import axios from "axios";

const contentStyle = {
    height: "100%",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
};

const Carousels = () => {
    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const list = localStorage.getItem("home-url");
        const listExpiry = localStorage.getItem("home-url-expry");
        const now = new Date();
        const expiryDate = new Date(JSON.parse(listExpiry));
        if (!list || (listExpiry && now > expiryDate)) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/get-dashboard-image-list`)
                .then((response) => {
                    // console.log("Response--->", response);
                    // console.log(response.data.dashboard_images);
                    setCard(response.data.dashboard_images);
                    localStorage.setItem(
                        "home-url",
                        JSON.stringify(response.data.dashboard_images)
                    );

                    // Assuming `ttl` is a property in `updatedCategories` with the expiry date
                    const resExpiry = new Date(response.data.ttl);
                    // console.log("Home Expiry-->", response.data.ttl, resExpiry);
                    localStorage.setItem("home-url-expry", JSON.stringify(resExpiry));
                    setLoading(false); // Set loading to false when data is fetched
                })
                .catch((error) => {
                    if (list) {
                        setCard(JSON.parse(list));
                        // console.log("old Response-->", JSON.parse(list));
                        setLoading(false);
                    } else {
                        setError(true);
                    }
                    // console.error('There was an error fetching the category list!', error);
                    setLoading(false); // Set loading to false in case of error
                });
        } else {
            // If data exists and is not expired, use the stored data
            setCard(JSON.parse(list));
            // console.log("Home old Response-->", JSON.parse(list));
            setLoading(false);
        }
    }, []); // Empty dependency array to run effect once on mount

    const carouselRef = React.useRef();

    const goToPrev = () => {
        carouselRef.current.prev();
    };

    const goToNext = () => {
        carouselRef.current.next();
    };

    return (
        <div className="slidein">
            <Carousel
                className="Top-carousel"
                autoplay={true}
                autoplaySpeed={2000}
                dots={false}
                ref={carouselRef}
                effect={"fade"}
            >
                {card.map((image, index) => (
                    <div key={index} className="carousel-img-wrapper">
                        <img
                            loading="lazy"
                            className="carousel-image"
                            alt="Sample"
                            style={contentStyle}
                            src={image.image}
                        />
                    </div>
                ))}
            </Carousel>
            <div className="carousel-buttons">
                <LeftOutlined className="prev-button" onClick={goToPrev}>
                    Previous
                </LeftOutlined>
                <RightOutlined className="next-button" onClick={goToNext}>
                    Next
                </RightOutlined>
            </div>
        </div>
    );
};

export default Carousels;
