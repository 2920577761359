import "./App.css";
import {Route, Routes} from "react-router-dom";
import Home from "./Pages/Home";
import Footers from "./Components/Footer/Footers";
import {Button, Layout} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import Gallery from "./Pages/Gallery";
import VideoList from "./Pages/VideoList";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Navbar from "./Components/Navbar/Navbar";
import Works from "./Pages/Works";
import SubCategories from "./Pages/SubCategories";
import ViewAll from "./Pages/ViewAll";
import {Analytics} from "@vercel/analytics/react";

// import { HashRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
    return (
        <div className="App">
            <Navbar/>
            <Layout>
                <Content style={{minHeight: "80vh"}}>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/works" element={<Works/>}/>
                        <Route path="/works/:categoryId" element={<SubCategories/>}/>
                        <Route path="/viewAll" element={<ViewAll/>}/>
                        <Route path="/gallery/:subId" element={<Gallery/>}/>
                        <Route path="/films" element={<VideoList/>}/>
                    </Routes>
                </Content>

                <Footers/>
            </Layout>
            <Analytics/>
        </div>
    );
}

export default App;
