import {Card, Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import axios from "axios";
import "../../App.css";
import {Link} from "react-router-dom";
import Error from "../../Pages/Error";

const {Meta} = Card;
const Cards = () => {
    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); // State for loading

    useEffect(() => {
        //debugger;
        const list = localStorage.getItem("list-url");
        const listExpiry = localStorage.getItem("list-url-expry");
        const now = new Date();
        const expryDate = new Date(JSON.parse(listExpiry));
        if (!list || (listExpiry && now > expryDate)) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/get-category-list`)
                .then((response) => {
                    debugger;
                    // console.log(" Home Card Response--->", response);
                    var updatedCategories = response.data.categories.map((data) => {
                        return {...data, url: `/${data.name}`};
                    });
                    const arrayLength = updatedCategories.length;
                    const randomNumber =
                        Math.floor(Math.random() * (arrayLength - 3)) + 3;

                    // console.log("category", updatedCategories, randomNumber);
                    const slicedCategories = updatedCategories.slice(
                        randomNumber - 3,
                        randomNumber
                    );
                    setCard(slicedCategories);
                    setError(false);

                    localStorage.setItem("list-url", JSON.stringify(updatedCategories));

                    // Assuming `ttl` is a property in `updatedCategories` with the expiry date
                    const resExpiry = new Date(response.data.ttl);
                    localStorage.setItem("list-url-expry", JSON.stringify(resExpiry));
                    setLoading(false); // Set loading to false when data is fetched
                })
                .catch((error) => {
                    if (list) {
                        const cardList = JSON.parse(list);
                        const arrayLength = cardList.length;
                        const randomNumber =
                            Math.floor(Math.random() * (arrayLength - 3)) + 3;
                        // If data exists and is not expired, use the stored data
                        setCard(cardList.slice(randomNumber - 3, randomNumber));
                        // console.log("Home Card old Response-->", JSON.parse(list));
                    } else {
                        setError(true);
                    }
                    // console.error('There was an error fetching the category list!', error);
                    setLoading(false); // Set loading to false in case of error
                });
        } else {
            const cardList = JSON.parse(list);
            const arrayLength = cardList.length;
            const randomNumber = Math.floor(Math.random() * (arrayLength - 3)) + 3;
            // If data exists and is not expired, use the stored data
            setCard(cardList.slice(randomNumber - 3, randomNumber));
            // console.log("Home Card old Response-->", JSON.parse(list));
            setLoading(false);
        }
    }, []);

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);
    return (
        <div className="container reveal">
            {error ? (
                <Error/>
            ) : (
                <Row gutter={[16, 16]}>
                    {card.map((card) => (
                        <Col className="cards-col" key={card.id}>
                            {/*<Card title={card.title}>*/}
                            {/*    <div className="card-image-container">*/}
                            {/*        <img src={card.imageUrl} alt={card.title} />*/}
                            {/*    </div>*/}
                            {/*</Card>*/}
                            <Link to={`works/${card.id}`}>
                                <Card
                                    className="cards"
                                    cover={<img alt="example" src={card.image} loading="lazy"/>}
                                    style={{alignItems: "center"}}
                                >
                                    <Meta
                                        className="cards-name"
                                        title={card.name}
                                        style={{textAlign: "center"}}
                                        // description="This is the description"
                                    />
                                    <div className="content">{card.name}</div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            )}
        </div>
    );
};

export default Cards;
