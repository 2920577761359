import React, {useEffect, useState} from "react";
import axios from "axios";
import "../App.css";
import {Row, Col, Card} from "antd";
import {Oval} from "react-loader-spinner";
import Error from "./Error";

const VideoList = () => {
    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); // State for loading

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/get-film-url-list`)
            .then((response) => {
                // console.log("Response--->", response);
                // console.log("New Response-->", response.data.film_urls);
                setCard(response.data.film_urls);
                setError(false);

                setLoading(false); // Set loading to false when data is fetched
            })
            .catch((error) => {
                setError(true);
                // console.error('There was an error fetching the category list!', error);
                setLoading(false); // Set loading to false in case of error
            });
    }, []);

    return loading ? (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <Oval
                height={80}
                width={80}
                color="rgb(57, 35, 19)"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#f3f3f3"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    ) : error ? (
        <Error/>
    ) : (
        <div className="Video">
            {/*<div className="Backbutton">*/}
            {/*    <BackButton />*/}
            {/*</div>*/}
            <div className="VideoList">
                <h1>FILMS</h1>
                <Row gutter={[12, 12]}>
                    {card.map((video) => (
                        <Col key={video.id} xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card
                                hoverable
                                cover={
                                    <iframe
                                        loading="lazy"
                                        height="300"
                                        width="250"
                                        className="videoList"
                                        src={video.url}
                                    />
                                }
                                style={{margin: "auto"}}
                                title={null}
                            >
                                <Card.Meta title={video.title}/>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default VideoList;
