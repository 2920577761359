import {useRef, useState} from "react";
import "../../App.css";
import emailjs from "@emailjs/browser";
import {Button, Form, Input, Modal, Typography, message} from "antd";

const {Title} = Typography;

const ContactForm = () => {
    const form = useRef();
    const [formFields] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const sendEmail = (values) => {
        emailjs
            .send("service_xtkhuc6", "template_obuooga", values, "NZWYcFXl8TPeyJoiw")
            .then(() => {
                formFields.resetFields();
                setIsModalVisible(false);
                message.success("Email sent successfully,we will get back soon", 5);
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });
    };

    return (
        <section>
            <div className="Contact-Form">
                <Button type="primary" className="contact_btn" onClick={showModal}>
                    FOR ENQUIRY
                </Button>

                <Modal
                    title="CONTACT FORM"
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Form
                        ref={form}
                        form={formFields}
                        className="form-control"
                        onFinish={sendEmail}
                    >
                        <Form.Item
                            name="name"
                            rules={[{required: true, message: "Please enter your name"}]}
                        >
                            <Input
                                className="form-item"
                                type="text"
                                placeholder="Your Name"
                            />
                        </Form.Item>
                        <Form.Item
                            name="service"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the service required",
                                },
                            ]}
                        >
                            <Input
                                className="form-item"
                                type="text"
                                placeholder="Service Required"
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {required: true, message: "Please enter your email"},
                                {type: "email", message: "Please enter a valid email"},
                            ]}
                        >
                            <Input
                                className="form-item"
                                type="email"
                                placeholder="Your Email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="number"
                            rules={[
                                {required: true, message: "Please enter your contact number"},
                                {
                                    pattern: /^[0-9]{10}$/,
                                    message: "Contact number must be 10 digits",
                                },
                            ]}
                        >
                            <Input
                                className="form-item"
                                type="number"
                                placeholder="Your Contact number"
                            />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            rules={[{required: true, message: "Please enter your message"}]}
                        >
                            <Input.TextArea
                                className="form-item"
                                placeholder="Your Message"
                                rows={4}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                type="primary"
                                className="submit-button"
                            >
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </section>
    );
};

export default ContactForm;
