import React, {useEffect, useState} from "react";
import "../App.css";
import {Button, Image} from "antd";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Oval} from "react-loader-spinner"; // Import the loader component
import Error from "./Error";

const Gallery = () => {
    const {subId} = useParams();

    const [card, setCard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); // State for loading
    useEffect(() => {
        debugger;
        const list = localStorage.getItem("gallery-url");
        const listExpiry = localStorage.getItem("gallery-url-expry");
        const now = new Date();
        const expryDate = new Date(JSON.parse(listExpiry));
        if (!list || (listExpiry && now > expryDate)) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/get-image-list/${subId}`)
                .then((response) => {
                    // console.log(response.data.images);
                    setCard(response.data.images);
                    setLoading(false); // Set loading to false when data is fetched
                })
                .catch((error) => {
                    // console.error('There was an error fetching the image list!', error);
                    setLoading(false); // Set loading to false in case of error
                    setError(true);
                });
        } else {
            const cardList = JSON.parse(list);
            const arrayLength = cardList.length;
            const randomNumber = Math.floor(Math.random() * (arrayLength - 3)) + 3;
            // If data exists and is not expired, use the stored data
            setCard(cardList.slice(randomNumber - 3, randomNumber));
            // console.log("Home Card old Response-->", JSON.parse(list));
            setLoading(false);
        }
    }, [subId]); // Add subId as a dependency

    return (
        <div className="app-gallery">
            {loading ? ( // Show loader while data is being fetched
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <Oval
                        height={80}
                        width={80}
                        color="rgb(57, 35, 19)"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#f3f3f3"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : error ? (
                <Error/>
            ) : (
                <div
                    style={{
                        padding: "10% 1% 1% 1%",
                        backgroundColor: "white",
                        margin: "auto",
                    }}
                >
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                    >
                        <Masonry columnsCount={3} gutter="10px">
                            {card.map((data) => (
                                <Image
                                    key={data.id}
                                    src={data.image}
                                    style={{width: "100%", display: "block"}}
                                    loading="lazy"
                                />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                    <br/>
                    <br/>
                </div>
            )}
        </div>
    );
};

export default Gallery;
