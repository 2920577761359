import React, {useEffect, useRef, useState} from 'react';
import {Row, Col, Button} from 'antd';
import '../../App.css';
import axios from 'axios'
import Error from '../../Pages/Error';
import {useNavigate} from 'react-router-dom';
import {Oval} from 'react-loader-spinner';

const Video = () => {
    // //debugger;
    const nav = useNavigate()
    // const videoRef = useRef(null);
    const handleWatch = () => {
        nav('/films')

    };
    const [card, setCard] = useState({url: ""});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);  // State for loading

    // State for loading


    useEffect(() => {
        //debugger;

        axios.get(`${process.env.REACT_APP_API_URL}/get-film-url-list`)
            .then(response => {
                // debugger
                // console.log("Response--->", response);
                // console.log("New Response-->", response.data.film_urls);
                const arrayLength = response.data.film_urls.length;
                const randomNumber = Math.floor(Math.random() * (arrayLength));
                setCard(response.data.film_urls[randomNumber]);
                // console.log("CARD Response-->", response.data.film_urls[randomNumber], randomNumber);
                setLoading(false); // Set loading to false when data is fetched
                setError(false)
            })
            .catch(error => {
                // console.error('There was an error fetching the category list!', error);
                setError(true)
                setLoading(false); // Set loading to false in case of error
            });

    }, []);

    return (<>{loading ? (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <Oval
                height={80}
                width={80}
                color="rgb(57, 35, 19)"
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#f3f3f3"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>) : (error ? (<Error/>) : (
        <Row gutter={[16, 16]} style={{margin: "auto", width: "100%",}}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {/* Video Player */}
                <iframe loading="lazy" className='tumbnail-video' src={card.url}/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {/* Content and Button */}
                <div>
                    <h2 className="films-text">FILMS</h2>
                    <p>We invite you to lose yourself in the visual symphony of our portfolio,
                        where every video composition is a melodic masterpiece.</p>
                    <Button className='watch_btn' type="primary" onClick={handleWatch}>Watch Us</Button>
                </div>
            </Col>
        </Row>))}
    </>)
};

export default Video;
