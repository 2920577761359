import React from 'react';
import {Button, Result} from 'antd';

const Error = () => (
    <Result
        status="500"
        title="Maintanace"
        subTitle="Sorry, something went wrong."
        extra={<p>We get Back Soon</p>}
    />
);
export default Error;